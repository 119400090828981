<template>
  <div class="new-issue px-6 py-4">
    <loading-state v-if="loading" />
    <columns>
      <column>
          <h2 class="title is-3 mb-5">New Issue</h2>
      </column>
    </columns>
    <columns>
      <column>
        <!-- form -->
        <form @submit.prevent="submitForm">
          <fieldset :disabled="loading">

              <!--- field -->
              <div class="field mb-5">
                <label class="label">Title <sup>*</sup></label>
                <div class="control">
                  <input v-model="title" class="input" type="text" placeholder="e.g [FEAT] Do something..." :disabled="loading">
                </div>
              </div>

              <!---// field -->

              <!-- field -->
              <div class="field mb-5">
                <label class="label">Description</label>
                <div class="control">
                  <!-- <textarea v-model="desc" class="textarea  is-link" placeholder=""></textarea> -->
                  <v-md-editor
                    v-model="desc"
                    height="300px"
                    mode="edit"
                    :left-toolbar="leftToolbar"
                     :disabled-menus="[]"
                    :right-toolbar="rightToolbar"
                    @upload-image="handleUploadImage"
                  />

                </div>
              </div>


              <!-- field -->
              <div class="field mb-5">
                <div class="field-body">
                  <!-- field -->
                  <div class="field">
                    <label class="label">Due date</label>
                    <div class="control">
                      <datepicker v-model="duedate" format="yyyy-MM-d" class="input" placeholder="YYYY-MM-DD"  />
                    </div>
                  </div>
                  <!-- field -->

                  <!-- field -->

                  <div class="field">
                    <label class="label">Assign to</label>
                    <div class="control">
                      <div class="select">
                        <select v-model="assignee_id">
                          <option selected>Choose a member</option>
                          <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- field -->
                  </div>
              </div>
              <!-- //field -->


              <div class="field is-grouped mt-6">
                <div class="control">
                  <button class="button is-primary px-6" :class="{'is-loadng': loading }">Create</button>
                </div>
                <div class="control">
                  <button class="button is-link is-light">Cancel</button>
                </div>
              </div>


          </fieldset>
        </form>
        <!--// form -->


      </column>
    </columns>
  </div>
</template>

<script>
  import { defineAsyncComponent, reactive, toRefs, ref, toRef, readonly } from 'vue'
  import { getUsersApi, issuesApi, uploadFileApi } from '@/use/useGitlabApi'
  import { getProjectIssues } from '@/use/useProject'
  import useGeneral from '@/use/useGeneral'
  import Datepicker from 'vue3-datepicker'

  export default {
    name: 'New Issue',
    props: {
      pid: {
        type: String,
        required: true
      }
    },
    components: {
      LoadingState: defineAsyncComponent(() => import('@/components/general/Loading')),
      Columns: defineAsyncComponent(() => import('@/components/ds/Columns.vue')),
      Column: defineAsyncComponent(() => import('@/components/ds/Column.vue')),
      Datepicker,
    },

    setup(props, context) {

      const state = reactive({
        title: '',
        desc: '',
        assignee_id: '',
        duedate: new Date(),
        file: ''
      })


      const editor = reactive({
        leftToolbar: 'undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image',
        rightToolbar: 'preview'
      })


      const file = ref(null)

      const project_path = toRef(props, 'pid')
      const { users } = getUsersApi()
      const { loading, error, issue, createIssue } = issuesApi()
      const { setSidebarHandle } = useGeneral();
      const { refetch } = getProjectIssues(project_path);


      const submitForm = () => {

        if (state.title === '') return


        let payload = Object.assign({}, { title: state.title, description: state.desc, assignee_id: state.assignee_id, duedate: state.duedate  })

        createIssue( encodeURIComponent(project_path.value), payload)

        if (issue) {
          resetForm()
          setSidebarHandle(false)
          refetch()
        }
      }

      const resetForm = () => {
        state.title = ''
        state.desc = ''
        state.assignee_id = ''
      }

      const handleUploadImage = async (event, insertImage, files) => {
        // Get the files and upload them to the file server, then insert the corresponding content into the editor
        // console.log(files[0]);

        let formData = new FormData();
        let gitlabURL = 'https://gitlab.vietswiss.io/' + project_path.value


        formData.append('file', files[0]);


        const file = await uploadFileApi(encodeURIComponent(project_path.value), formData)

        if (file) {
          // console.log(file);

          insertImage({
            url: gitlabURL + file.url,
            desc: file.alt
          });

        }

        // markdown: "![logo-vietswiss](/uploads/93a61c948cb26c13984c920ee37b2fe1/logo-vietswiss.jpg)"
        // url: "/uploads/93a61c948cb26c13984c920ee37b2fe1/logo-vietswiss.jpg"

        // ![red](https://gitlab.vietswiss.io/lottie/lottie-cms/uploads/c3f478b050724c3798369b03611e1072/red.jpg)
      }



      return {
        ...toRefs(state),
        ...toRefs(editor),
        users,
        loading,
        submitForm,
        handleUploadImage
      }
    }

  }
</script>

<style lang="scss">
  .new-issue {

  }

  .v-md-editor {
    box-shadow: none;
    border: 1px solid #dedede;

    &--fullscreen {
      top: 80px;
    }
  }

</style>
